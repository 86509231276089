import React from 'react'
import FormConfirmation from './FormConfirmation'
import ActionBanner from './ActionBanner'

export default function PatientSupportConfirmation() {
  return (
    <div className="form-confirmation__container">
      <div className="page-section">
        <FormConfirmation>
          <p>
            An Aidia Specialist will get back to you within the next 48 hours to answer your question. If you prefer to
            speak to a specialist directly, please call 1-800-381-9384 or contact the pharmacy that enrolled you in the
            smart bottle program.
          </p>
          <p>
            <span className="bold">Please note</span>: If you are in need of medical attention or this is a medical
            emergency, please contact your healthcare provider or pharmacy or call 911 for immediate medical attention.
          </p>
          <p>
            <span className="bold">Aviso</span>: Si necesita atención médica de emergencia, por favor contacte a su
            médico principal, farmacia o llame al 911 para atención médica inmediata.
          </p>
        </FormConfirmation>
      </div>
      <ActionBanner
        header={null}
        content="Meanwhile, check out these frequently asked questions."
        linkPath="/faqs"
        linkText="View FAQs"
      />
    </div>
  )
}
