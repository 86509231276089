import React from 'react'
import { FaExclamationTriangle } from 'react-icons/fa'

export default function ServerError() {
  return (
    <span className="server-error">
      <FaExclamationTriangle className="exclamation-triangle" size={30} />
      We encountered a server error and your form was not submitted. Please try again.
    </span>
  )
}
