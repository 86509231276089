import React, { useState } from 'react'
import PatientSupportConfirmation from '../components/PatientSupportConfirmation'
import PatientSupportForm from '../components/PatientSupportForm'

export default function patientSupportPage() {
  const [isSubmitted, setIsSubmitted] = useState(false)

  if (isSubmitted) {
    return (
      <main>
        <PatientSupportConfirmation />
      </main>
    )
  }

  return (
    <main>
      <PatientSupportForm setIsSubmitted={setIsSubmitted} />
    </main>
  )
}
