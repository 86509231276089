import React, { useState } from 'react'
import { Formik, Form, Field } from 'formik'
import { Link } from 'gatsby'
import * as Api from '../utils/api'
import Input from './Input'
import Textarea from './Textarea'
import Checkbox from './Checkbox'
import RadioList from './RadioList'
import TelephoneInput from './TelephoneInput'
import Button from './Button'
import { FaArrowRight } from 'react-icons/fa'
import ServerError from './ServerError'
import { hasError, responsePreferenceOptions, timezoneOptions, timeOfDayOptions } from '../utils/form'

export default function PatientSupportForm({ setIsSubmitted }) {
  const initialValues = {
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    bottle_number: '',
    preferred_contact_response: '',
    time_zone_preference: '',
    time_of_day_response_preference: '',
    message: '',
    marketing_contact_opt_in: false,
    mapleSyrup: '',
  }
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const handleFormSubmit = async (values) => {
    try {
      setLoading(true)
      setError(null)

      // https://www.thryv.com/blog/honeypot-technique/
      // can implement on backend
      if (values.mapleSyrup) {
        setError({ message: 'Failed to submit.', code: 409 })
        return
      }
      delete values.mapleSyrup

      await Api.postPatientSupport(values)
      setLoading(false)
      setError(null)
      setIsSubmitted(true)

      window.scrollTo(0, 0)
    } catch (error) {
      setLoading(false)
      setError({ message: error.error, code: error.code })
    }
  }
  const validate = (values) => {
    const errors = {}

    if (!values.firstname) {
      errors.firstname = 'Please enter your first name.'
    }

    if (!values.lastname) {
      errors.lastname = 'Please enter your last name.'
    }

    if (!values.email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = "That email address doesn't look quite right."
    }

    if (!values.phone || values.phone.length !== 14) {
      errors.phone = 'Please confirm that your phone number is correct.'
    }

    if (values.bottle_number) {
      if (!/^[a-z|A-Z]{3,3}[0-9]{3,3}$/i.test(values.bottle_number)) {
        errors.bottle_number = 'Please confirm your bottle number is correct.'
      }
    }

    if (!values.preferred_contact_response) {
      errors.preferred_contact_response = 'Please select a preferred response.'
    }

    if (!values.message) {
      errors.message = 'Please enter how we can help.'
    }

    if (!values.marketing_contact_opt_in) {
      errors.marketing_contact_opt_in = 'Please check the box above to proceed.'
    }

    return errors
  }

  return (
    <>
      <div className="page-section">
        <div className="form-container patient-support-form">
          <h1 className="header">Contact an Aidia Specialist</h1>
          <div>
            <div className="patient-support-notice">
              <div className="english">
                <p>
                  If you are enrolled in a smart bottle or cap system and need assistance with your smart device or
                  notifications, complete the form below and an Aidia Specialist will reach out to you within 48 hours.
                </p>
                <p>
                  <span className="bold">Please note</span>: If you are in need of medical attention or this is a
                  medical emergency, please contact your healthcare provider or pharmacy or call 911 for immediate
                  medical attention.
                </p>
              </div>
              <div className="spanish">
                <p>
                  Si está inscrito en un sistema de frasco o de tapa inteligente y necesita ayuda con su aparato
                  inteligente o con las notificaciones, llene el siguiente formulario y un especialista de Aidia le
                  contactará dentro de las próximas 48 horas.
                </p>
                <p>
                  <span className="bold">Aviso</span>: Si necesita atención médica de emergencia, por favor contacte a
                  su médico principal, farmacia o llame al 911 para atención médica inmediata.
                </p>
              </div>
            </div>
          </div>
          <Formik
            initialValues={initialValues}
            validate={(values) => validate(values)}
            onSubmit={async (values) => await handleFormSubmit(values)}
          >
            {({ values, isSubmitting, isValid, errors, submitCount, setFieldValue }) => {
              return (
                <Form>
                  <fieldset>
                    <Input name="firstname" label="First name" hasError={hasError('firstname', submitCount, errors)} />
                    <Input name="lastname" label="Last name" hasError={hasError('lastname', submitCount, errors)} />
                    <Input
                      name="email"
                      label="Email address"
                      type="email"
                      hasError={hasError('email', submitCount, errors)}
                    />
                    <TelephoneInput
                      name="phone"
                      label="Phone number (associated with reminder system)"
                      hasError={hasError('phone', submitCount, errors)}
                      previousValue={values.phone}
                      setFieldValue={setFieldValue}
                    />
                    <Input
                      name="bottle_number"
                      label="Device # (3 Letters and 3 Numbers)"
                      sublabel="Smart Bottle # can be found on bottom of device. Smart Cap # can be found on the top of the device."
                      hasError={hasError('bottle_number', submitCount, errors)}
                    />
                    <RadioList
                      name="preferred_contact_response"
                      label="Preferred response"
                      options={responsePreferenceOptions}
                      hasError={hasError('preferred_contact_response', submitCount, errors)}
                      className="preference"
                      horizontal
                    />
                    {values.preferred_contact_response && (
                      <>
                        <RadioList
                          name="time_zone_preference"
                          label="US time zone:"
                          options={timezoneOptions}
                          hasError={hasError('time_zone_preference', submitCount, errors)}
                          horizontal
                        />
                        <RadioList
                          name="time_of_day_response_preference"
                          label="Time of day:"
                          options={timeOfDayOptions}
                          hasError={hasError('time_of_day_response_preference', submitCount, errors)}
                          horizontal
                        />
                      </>
                    )}
                    <Textarea
                      name="message"
                      label="How can we help you?"
                      hasError={hasError('message', submitCount, errors)}
                    />
                    <Checkbox
                      name="marketing_contact_opt_in"
                      hasError={hasError('marketing_contact_opt_in', submitCount, errors)}
                      label={
                        <span>
                          By checking this box and clicking “Submit” you agree to be contacted by AdhereTech agents by
                          direct mail, email, telephone, and text message (including autodialed and prerecorded calls
                          and messages—msg & data rates may apply) for marketing purposes and other information related
                          to adherence and other support services from AdhereTech. Your consent is not a condition of
                          any purchase. You may choose to no longer receive further communications from AdhereTech by
                          emailing{' '}
                          <a href="mailto:unsubscribe@adheretech.com?subject=Unsubscribe from AdhereTech communications">
                            unsubscribe@adheretech.com
                          </a>{' '}
                          following the unsubscribe instructions on the communication. For more information, please view
                          AdhereTech's <Link to="/privacy-policy">Privacy Policy</Link>.
                        </span>
                      }
                    />

                    <Field type="text" name="mapleSyrup" />
                  </fieldset>
                  <Button color="blue" type="submit" disabled={isSubmitting || (submitCount > 0 && !isValid)}>
                    <span>Submit</span>
                    <FaArrowRight className="arrow-right" />
                  </Button>
                </Form>
              )
            }}
          </Formik>
          {error && <ServerError />}
        </div>
      </div>
    </>
  )
}
